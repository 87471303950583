module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-obsidian","id":"f245288a-95f3-5228-9792-59b7184dc805","name":"gatsby-remark-obsidian","version":"0.5.0","modulePath":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-obsidian/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.18.0","modulePath":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/zhannum/git/the-dark-between/plugins/gatsby-remark-hidden","id":"74b8d543-26ef-5b15-abab-d7faad8c14f7","name":"gatsby-remark-hidden","version":"0.1.0","modulePath":"/Users/zhannum/git/the-dark-between/plugins/gatsby-remark-hidden/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-images-anywhere","id":"f248c779-fba4-5463-a233-cf37ea5bea68","name":"gatsby-remark-images-anywhere","version":"1.3.1","modulePath":"/Users/zhannum/git/the-dark-between/node_modules/gatsby-remark-images-anywhere/index.js","pluginOptions":{"plugins":[],"backgroundColor":"transparent","maxWidth":1500},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/zhannum/git/the-dark-between","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
