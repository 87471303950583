import physical from "../../../../src/pages/images/global_physical.jpg";
import atmoAndIso from "../../../../src/pages/images/atmospheric_pressure_and_isotherms.jpg";
import precAndEvap from "../../../../src/pages/images/precipitation_and_evaporation.jpg";
import climate from "../../../../src/pages/images/climate_zones.jpg";
import * as React from 'react';
export default {
  physical,
  atmoAndIso,
  precAndEvap,
  climate,
  React
};